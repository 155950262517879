import { navigate } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import BottomSection from "../components/intro/bottom-section"
import TopSection from "../components/intro/top-section"
import Video from "../components/intro/video"
import VideoOverlay from "../components/intro/video-overlay"

import ImageStep1 from "../images/intro-step1.png"
import ImageStep3 from "../images/intro-step2.png"
import ImageStep2 from "../images/intro-step3.png"
import ThemeConfig from "../theme/theme-config"

const IntroPage = () => {
  const [selectedStep, setSelectedStep] = useState(1)
  const [language, setLanguage] = useState(null)

  const stepsContent = [
    {
      title: "Welcome to Nutribrands",
      description:
        "Here at Nutribrands we have this one consuming passion: Helping you and your family maintain a healthy, medicine free, lifestyle.<br/><br/>Because we truly believe that all of us can fight most diseases, without medicine, if we maintain a strong immune system with the right fuel or natural nutrition, on a daily basis.<br/><br/>Which is why we have been manufacturing and supplying Nucleo as a very affordable All-in-One meal replacement or energy shake, for more than 15 years now.<br/><br/>We have seen the power of healthy nutrition and a strong immune system first hand. Over the last 15 years, our children grew up with their daily Nucleo and recovered from various illnesses without any antibiotics. And thousands of families using Nucleo daily, are having a similar experience.<br/><br/>As the manufacturer, we ensure great cost savings by supplying Nucleo directly to customers through this site. We also distribute via a few health shops and doctors, who use Nucleo in their own families.<br/><br/>We achieved phenomenal savings by combining 43 ingredients (mostly imported from Europe) in Nucleo. This ensures that Nucleo is about 5 times cheaper per daily meal/supplement, than having to purchase all those ingredients separately. See our Blog for more detail.<br/><br/>You will find the various categories of products on our Home or Shop page. We use all these products ourselves as they are effective and the best value for money we could find.",
      topSection: <img src={ImageStep1}></img>,
    },
    {
      title: "Secure Payment and Fast Delivery",
      description:
        "At checkout you will have various payment options through Payfast's secure card and instant EFT service.<br/><br/>Click on MY ACCOUNT to change your profile or shipping address. You can view or reorder previous orders under ORDER HISTORY.<br/><br/>Look out for emails from Nutribrands (check spam box) confirming when your order has been received, processed and packed. You will receive an email from the courier (with waybill information), once your order has been shipped from our warehouse in Pretoria. You can also choose to collect from our warehouse.<br/><br/>Orders packed before 14:00 (Mon-Fri) will be delivered the next working day in Gauteng. Further deliveries could take 2 to 6 workdays.",
      topSection: <img src={ImageStep2}></img>,
    },
    {
      title: "A Special Gift For You",
      description:
        "If this is your first time connecting with or ordering from Nutribrands, we would like to check in personally with you to thank you and see if we can help you further. So look out for a whatsapp message from owner, Jacques de Bruin, over the next few days.<br/><br/>As a special thankyou for supporting us, we offer a discount of R50 per 750g pack, if you order 3 or more packs of Nucleo.<br/><br/>All you have to do is to add nucleodiscount in the \"Add promo code\" field in your cart, before you click on Checkout and you will see your discount.<br/><br/>This discount applies to any combination of the three Nucleo flavours. Note that we don't currently offer discounts on any of the other products, but any future discounts will be notified on the shop page.<br/><br/>Here's to you and your family's health,<br/><br/>Jacques<br/><br/>Nutribrands",
      topSection: <img src={ImageStep3}></img>,
    },
    // {
    //   title: "You’re Almost There!",
    //   description:
    //     "Before we get started, we’d love to tell you a bit about what we have to offer. Meet Nutribrands owner, Jacques de Bruin, in the video. Alternatively you can skip this step by clicking on the “Skip” button below.",
    //   topSection: <Video src=""></Video>,
    // },
  ]

  const handleContinue = () => {
    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1)
    } else {
      navigate("/")
    }
  }

  const handleBack = () => {
    setSelectedStep(selectedStep - 1)
  }

  return (
    <ThemeConfig>
      <Container>
        <TopSection>
          {selectedStep === 4
            ? React.cloneElement(stepsContent[selectedStep - 1].topSection, {
                src:
                  language === "Afrikaans"
                    ? "https://www.youtube.com/embed/oP2oGREukAE"
                    : "https://www.youtube.com/embed/BiK6wIOKiuk",
              })
            : stepsContent[selectedStep - 1].topSection}
          <Pagination selectedButton={selectedStep}>
            <PaginationButton />
            <PaginationButton />
            <PaginationButton />
            {/* <PaginationButton /> */}
          </Pagination>

          {/* <button
            className="continue"
            onClick={() => {
              handleContinue()
            }}
          >
            {selectedStep < 3 ? "Continue" : "Done"}
          </button> */}
          {language === null && selectedStep === 4 ? (
            <VideoOverlay
              language={language}
              setLanguage={setLanguage}
            ></VideoOverlay>
          ) : null}
        </TopSection>
        <BottomSection>
          <h3>{stepsContent[selectedStep - 1].title}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: stepsContent[selectedStep - 1].description,
            }}
          ></p>

          <button
            className="continue"
            onClick={() => {
              handleContinue()
            }}
          >
            {selectedStep < 3 ? "Continue" : "Done"}
          </button>

          {selectedStep > 1 ? (
            <button
              className="skip"
              onClick={() => {
                handleBack()
              }}
            >
              Back
            </button>
          ) : null}
        </BottomSection>
      </Container>
    </ThemeConfig>
  )
}

export default IntroPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`
const Pagination = styled.div`
  display: flex;
  margin-top: 48px;
  margin-bottom: 38px;

  div:nth-child(${({ selectedButton }) => selectedButton}) {
    background-color: #34a21f;
    opacity: 1;
  }
`

const PaginationButton = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background-color: #03111f;
  margin: 3px;
  opacity: 0.2;
`
