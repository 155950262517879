import React from "react"
import styled from "styled-components"

const BottomSection = ({ children }) => {
  return <Container children={children}></Container>
}

export default BottomSection

const Container = styled.div`
  background-color: white;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 20px;

  h3 {
    text-align: center;
    font: normal normal 600 28px/40px Poppins;
    margin-bottom: 30px;
  }

  p {
    text-align: center;
    color: #5b5b5b;
    margin-bottom: 20px;
    margin-top: 0;
    max-width: 90%;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      font: normal normal normal 16px/30px Poppins;
    }
  }

  button.continue {
    width: 350px;
    height: 50px;
    color: white;
    border: none;
    background-color: #7fac2a;
    border-radius: 25px;
    text-align: center;

    &:hover {
      background-color: #34a21f;
    }
  }

  button.skip {
    margin-top: 20px;
    color: #5b5b5b;
    text-align: center;
    background-color: white;
    border: none;
    font: normal normal bold 16px/25px Poppins;
  }
`
