import React from "react"
import styled from "styled-components"

const Video = ({ src = "" }) => {
  return (
    <Container>
      <EmbedStyled type="video/webm" src={src}></EmbedStyled>
    </Container>
  )
}

export default Video

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const EmbedStyled = styled.embed`
  width: 500px;
  height: 300px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 450px;
    height: 275px;
  }
`
