import React from "react"
import styled from "styled-components"

const TopSection = ({ children }) => {
  return <Container children={children}></Container>
}

export default TopSection

const Container = styled.div`
  background-color: #f5f5f5;
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 50%;
  }

  img {
    max-height: 350px;
    padding-top: 20px;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      max-height: 250px;
    }
  }

  button {
    margin-bottom: 15px;
    font: normal normal normal 16px/25px Poppins;
    cursor: pointer;
    height: 50px;
    width: fit-content;
  }

  button.skip {
    color: #5b5b5b;
    text-align: center;
    background-color: white;
    border: none;
    font: normal normal bold 16px/25px Poppins;
  }
`
