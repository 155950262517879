import React, { useState } from "react"
import styled from "styled-components"

const VideoOverlay = ({ setLanguage = () => {} }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  return (
    <Container>
      <Title>One Last Step!</Title>
      <Description>
        Before watching the Introduction video, please select your preferred
        language you’d like the video to be played in from the following
        options:
      </Description>
      <OptionsContainer>
        <Option>
          <OptionButton
            className={selectedOption === "English" ? "selected" : ""}
            onClick={() => {
              setSelectedOption("English")
            }}
          ></OptionButton>
          <OptionText>English</OptionText>
        </Option>
        <Option>
          <OptionButton
            className={selectedOption === "Afrikaans" ? "selected" : ""}
            onClick={() => {
              setSelectedOption("Afrikaans")
            }}
          ></OptionButton>
          <OptionText>Afrikaans</OptionText>
        </Option>
      </OptionsContainer>
      <button
        onClick={() => {
          setLanguage(selectedOption)
        }}
      >
        Watch Video
      </button>
    </Container>
  )
}

export default VideoOverlay

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(245, 245, 245, 0.95);

  button {
    margin: 15px;
    font: normal normal normal 16px/25px Poppins;
    cursor: pointer;
    width: 230px;
    height: 50px;
    color: white;
    border: none;
    background-color: #7fac2a;
    border-radius: 25px;
    text-align: center;

    &:hover {
      background-color: #34a21f;
    }
  }
`
const Title = styled.h3`
  text-align: center;
  font: normal normal 600 28px/72px Poppins;
  color: #03111f;
`
const Description = styled.p`
  text-align: center;
  font: normal normal normal 21px/35px Poppins;
  max-width: 500px;
  color: #03111f;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font: normal normal normal 16px/30px Poppins;
    max-width: 90%;
  }
`
const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Option = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  align-items: center;
`
const OptionText = styled.h5`
  font: normal normal bold 18px/18px Poppins;
  letter-spacing: 0.72px;
  color: #03111f;
  vertical-align: middle;
`
const OptionButton = styled.div`
  border: 3px solid #34a21f;
  border-radius: 100%;
  background-color: white;
  opacity: 1;
  width: 23px;
  height: 23px;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    background-color: #34a21f;
  }

  &.selected {
    background-color: #34a21f;
  }
`
